import { Alert, Box, FormControl, FormLabel, Typography, Skeleton } from "@mui/material";
import { DateTime } from "luxon";
import { ReactElement } from "react";
import colors from "../../colors";
import { BookingSlot, ExternalMeetingInfo, MeetingQuestionAnswerSubmission, MeetingStatus } from "../../store";
import { getAnswerString } from "../../utils/scheduleUtils";


const dayFormat = 'cccc MMMM d, yyyy';


export const ConfirmationHeader = ({ confirmed, noTimesWork, isErrorNotice }: {
  confirmed: boolean, noTimesWork: boolean, isErrorNotice: boolean
}) => {
  if (isErrorNotice) {
    return noTimesWork ? <>Unable to send notice</> : <>Meeting not scheduled</>;
  }
  
  return noTimesWork ? <>Notice Sent!</> : <>Thanks for your submission!</>;
};

export const ConfirmationSubHeader = ({ confirmed, noTimesWork, isErrorNotice }: {
  confirmed: boolean, noTimesWork: boolean, isErrorNotice: boolean
}) => {
  if (isErrorNotice) {
    return <>Something went wrong</>;
  }

  return noTimesWork ? <>We've let the organizer know that none of these times work.</>
    : confirmed ? <>A meeting invitation will be sent to the following emails</>
      : <>You will receive a calendar invite once the event has been scheduled.</>;
};

export interface ConfirmationProps {
  selectedDay: DateTime | null;
  slotSelected: BookingSlot | null;
  meeting: ExternalMeetingInfo | null;
  currentTimezone: string;
  confirmed: boolean;
  isErrorNotice: boolean;
  noTimesWork: boolean
  noTimesWorkSent: boolean
  questionAnswers: MeetingQuestionAnswerSubmission[]
  loadingAnswers: boolean
}

const Confirmation = ({
  selectedDay, meeting, slotSelected, currentTimezone, confirmed, isErrorNotice,
  noTimesWork, noTimesWorkSent, questionAnswers: answers, loadingAnswers
}: ConfirmationProps): ReactElement => {

  const user = meeting?.create_user;
  const startTime = meeting?.status.id === MeetingStatus.SCHEDULED ? meeting?.event_start_time : slotSelected?.start;
  const daySelected = meeting?.status.id === MeetingStatus.SCHEDULED ? DateTime.fromISO(meeting?.event_start_time || '')
    .toFormat(dayFormat) : 
    DateTime.fromISO(slotSelected?.start || '').toFormat(dayFormat);
  const start = startTime ? DateTime.fromISO(startTime).setZone(currentTimezone).toFormat('h:mm a') : '';
  const end = startTime ? DateTime.fromISO(startTime)
    .setZone(currentTimezone)
    .plus({ minutes: meeting?.duration_minutes })
    .toFormat('h:mm a (ZZZZ)') : '';

  if (isErrorNotice) {
    return (
      <Box>
        <Typography variant="h5" fontWeight={400} maxWidth={'720px'}>
          We're sorry, something went wrong. Please contact:&nbsp; 
          <b>{`${user?.email}`}</b>
        </Typography>
      </Box>
    );
  }

  if (meeting?.status.id === MeetingStatus.CANCELLED) {
    return (
      <Alert severity='info' sx={{ maxWidth: 'fit-content', marginBottom: 1, minWidth: 150 }}>
        This meeting has been <b>{meeting.status.name}</b>. 
        Reach out to <b>{meeting.create_user.email}</b> to schedule.
      </Alert>
    );
  }

  return (
    <Box display='flex' flexDirection='column' gap={4}>
      <Typography variant="h3" fontSize={26} fontWeight={700}>
        {!noTimesWork ? "Meeting Confirmation" : "Notice Confirmation"}
      </Typography>

      <Box display='flex' gap={4} flexWrap='wrap'>
        {meeting && meeting.participants &&
          Object.values(meeting.participants).map(participant => {
            return <Box key={participant.id}>
              <Typography variant="h2">{participant.name}</Typography>
              {/* <Typography variant="h2">{participant.email}</Typography> */}
            </Box>;
          })
        }
      </Box>
      <Box display='flex' flexDirection='column' width={'fit-content'} marginTop={2} gap={1}>
        {startTime ? (
          <>
            <Typography variant='h5' fontWeight={700}>Meeting Time</Typography>
            <Typography variant='body1' fontWeight={500}>
              {meeting?.status.id === MeetingStatus.SCHEDULED && meeting.event_start_time
                ? DateTime.fromISO(meeting.event_start_time).setZone(currentTimezone).toFormat(dayFormat)
                : selectedDay?.toFormat(dayFormat) || daySelected}
            </Typography>
            <Typography variant='body2'>{start} - {end}</Typography>
          </>
        ) : (
          <>
            {!noTimesWork &&
            <Alert severity='info' sx={{ maxWidth: 'fit-content', marginBottom: 1, minWidth: 150 }}>
              <span>This meeting has been scheduled outside of Cabinet</span>
            </Alert>
            }
          </>
        )}
      </Box>
      {meeting?.allow_reschedule_cancel && (
        <Typography variant="body2" color={colors.black800}>
          {startTime ? (
            <>Use the links on your calendar event to reschedule or cancel this meeting</>
          ) : (
            <>Email <b>{meeting.create_user.email}</b> to make changes to this event</>
          )}
        </Typography>
      )}
      {meeting?.questions && meeting?.questions?.length > 0 && answers && (
        <Box marginTop={2}>
          <Box width={'100%'} borderBottom='1px solid' borderColor={colors.black200}></Box>
          <QuestionResponses questions={meeting?.questions} answers={answers} loading={loadingAnswers} />
        </Box>
      )}
    </Box>
  );
};

export default Confirmation;


type QuestionProps = {
  questions: ExternalMeetingInfo['questions'] | undefined
  answers: MeetingQuestionAnswerSubmission[];
  loading: boolean;
};

const QuestionResponses = ({ questions, answers, loading }: QuestionProps) => {
  return (
    <Box marginTop={1}>
      {questions?.slice().sort((a, b) => (a?.order || 0) - (b?.order || 0)).map((question) => {
        const answer = answers.find(curAnswer => curAnswer.question === question.id);
        return (
          <FormControl key={question.id} fullWidth>
            <FormLabel sx={{ marginTop: 2 }}>{question.required ? question.title + ' *' : question.title}</FormLabel>
            {loading ? (
              <Skeleton width={200} height={30} />
            ) : (
              <>
                {answer ? (
                  <Box sx={{ width: '100%' }}>
                    <Typography marginTop={1} fontWeight={600}>
                      {getAnswerString(answer, question, '- no answer -')}
                    </Typography>
                  </Box>
                ) : (
                  <Box sx={{ width: '100%' }}>
                    <Typography marginTop={1} fontWeight={600}> - no answer - </Typography>
                  </Box>
                )}
              </>
            )}
          </FormControl>
        );
      })}
    </Box>
  );
};
