import { CabButton } from "@CabComponents/CabButton";
import { CabIcon } from "@CabComponents/CabIcon";
import { CabTextInput } from "@CabComponents/CabTextInput";
import { Alert, Box, FormControl, FormLabel, IconButton, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { ReactElement, useEffect, useMemo } from "react";
import { Control, Controller, useFieldArray, useForm } from "react-hook-form";
import colors from "../../colors";
import { emailRegex } from "../../constants";
import { 
  BookingSlot, ExternalMeetingInfo, MeetingQuestion, MeetingQuestionAnswerSubmission, 
  NormalizedExternalParticipant 
} from "../../store";
import { useMountEffect } from "../../utils/hooks";
import { IoAdd, IoCloseCircleOutline } from "react-icons/io5";
import { CabAutocomplete } from "@CabComponents/CabAutocomplete";
import { QuestionType } from "../../store";


export const AttendeeInfoHeader = () => {
  return (
    <>Confirm your information</>
  );
};

export const AttendeeInfoSubHeader = () => {
  return (
    <></>
  );
};

export interface AttendeeInfoProps {
  selectedDay: DateTime | null;
  currentTimezone: string;
  slotSelected: BookingSlot | null;
  meeting: ExternalMeetingInfo | null;
  isBooking: boolean;
  allowAddParticipants: boolean;
  isReschedulingURL?: boolean;
  isPreview?: boolean;
  setName: (name: string) => void;
  handleScheduleMeeting: (externalParticipants: NormalizedExternalParticipant[], 
    meetingQuestionAnswers?: MeetingQuestionAnswerSubmission[]) => void;
  prefillName?: string;
  prefillEmail?: string;
  rebookParticipants: NormalizedExternalParticipant[];
  rebookAnswers: MeetingQuestionAnswerSubmission[];
  onFormStateChange?: (state: { isValid: boolean }) => void;
}


const AttendeeInfo = ({
  meeting, isBooking, setName, handleScheduleMeeting, allowAddParticipants, isPreview,
  prefillEmail, prefillName, rebookParticipants, rebookAnswers, onFormStateChange
}: AttendeeInfoProps): ReactElement => {

  const defaultParticipant: NormalizedExternalParticipant = useMemo(() => ({
    id: -1, name: prefillName || '', email: prefillEmail || '', first_response_date: null, meeting: meeting?.id || -1,
    email_hash: '', required: true, no_times_comment: null, calendar_access: null
  }), [prefillName, prefillEmail, meeting?.id]);

  const {
    control, getValues, watch, formState, setFocus,
  } = useForm<{participants: NormalizedExternalParticipant[], answers: MeetingQuestionAnswerSubmission[]}>({
    values: {
      participants: rebookParticipants.length ? rebookParticipants : [defaultParticipant],
      answers: rebookAnswers
    },
  });

  const { fields, append, remove } = useFieldArray({control, name: 'participants'});

  useMountEffect(() => {
    setFocus(`participants.${0}.name`);
  });

  const name = watch('participants');
  useEffect(() => {
    setName(name[0].name);
  }, [name, setName]);

  useEffect(() => {
    onFormStateChange?.(formState);
  }, [formState, onFormStateChange]);

  const handleSchedule = (e: React.FormEvent) => {
    if (meeting && meeting.questions) {
      handleScheduleMeeting(getValues()['participants'], getValues()["answers"]);
    } else {
      handleScheduleMeeting(getValues()['participants']);
    }
    e.preventDefault();
  };

  const handleEnterSubmit = (key: string) => {
    if (key === 'Enter' && formState.isValid) {
      if (meeting && meeting.questions) {
        handleScheduleMeeting(getValues()['participants'], getValues()["answers"]);
      } else {
        handleScheduleMeeting(getValues()['participants']);
      }
    }
  };

  return (
    <Box display='flex' flexDirection='column' gap={4}>
      {/* <Box display='flex' flexDirection='column' gap={2}>
        <Typography variant="h1" fontSize={38}>Confirm your information</Typography>
      </Box> */}
      <form onSubmit={handleSchedule}>
        <Box display='flex' flexDirection='column' gap={3}>
          {fields.map((participant, index) => {
            return (
              <Box key={participant.id} display='flex' flexDirection='column' gap={2}>
                <Controller 
                  name={`participants.${index}.name`}
                  defaultValue={`${participant.name}`}
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { ref, ...field } }) => (
                    <Box display='flex' flexDirection='column'>
                      <Box display='flex' justifyContent='space-between'>
                        <FormLabel>Name*</FormLabel>
                        {index !== 0 && 
                        <IconButton edge='end' aria-label='remove' disableRipple sx={{padding: 0}}
                          onClick={() => remove(index)}
                        >
                          <CabIcon Icon={IoCloseCircleOutline} alt='Remove' />
                        </IconButton>
                        }
                      </Box>
                      <CabTextInput
                        {...field}
                        inputRef={ref}
                        sx={{width: '98%'}}
                        onKeyDown={(e) => handleEnterSubmit(e.key)}
                        required
                      />
                    </Box>
                  )}
                />
                <Controller 
                  name={`participants.${index}.email`}
                  defaultValue={`${participant.email}`}
                  control={control}
                  rules={{ required: true, pattern: emailRegex }}
                  render={({ field: { ref, ...field } }) => (
                    <Box display='flex' flexDirection='column'>
                      <FormLabel>Email*</FormLabel>
                      <CabTextInput
                        {...field}
                        type='email'
                        inputRef={ref}
                        sx={{width: '98%'}}
                        required
                      />
                      <Typography color={colors.black700}>
                        An invite will be sent to this address
                      </Typography>
                    </Box>
                  )}
                />
              </Box>
            );
          })}
          {allowAddParticipants && (
            <Box>
              <CabButton
                buttonType="tertiary"
                color='accent'
                onClick={() => append(defaultParticipant)}
                icon={<CabIcon Icon={IoAdd} alt='Add'/>}
              >
                Add Participant
              </CabButton>
            </Box>
          )}
          {meeting?.questions?.length !== undefined && meeting.questions.length > 0 && (
            <Box display='flex' flexDirection='column' gap={2}>
              <Typography variant="h1" fontSize={20} lineHeight={'41px'} marginTop={1}>
                Additional Meeting Questions
              </Typography>
              <Box display='flex' flexDirection='column' rowGap={4}>
                {meeting.questions.slice().sort((a, b) => (a?.order || 0) - (b?.order || 0)).map((question, idx) => {
                  return <Question
                    key={question.id}
                    question={question}
                    control={control}
                    idx={idx}
                  />;
                })}
              </Box>
            </Box>
          )}
        </Box>
      </form>
    </Box>
  );
};

export default AttendeeInfo;

type QuestionProps = {
  question: MeetingQuestion
  control: Control<{
    participants: NormalizedExternalParticipant[],
    answers: MeetingQuestionAnswerSubmission[];
  }>
  idx: number;
};

const Question = ({question, control, idx}: QuestionProps) => {
  return (
    <FormControl fullWidth>
      <FormLabel>{question.required ? question.title + ' *' : question.title}</FormLabel>
      {question.question_type === QuestionType.TEXT ? (
        <Controller
          control={control}
          name={`answers.${idx}.text`}
          rules={{
            required: question.required,
            maxLength: 4000
          }}
          render={({field: {onChange, value, ref}, fieldState: {error, invalid}}) => <Box sx={{width: '100%'}}>
            <CabTextInput
              multiline
              fullWidth
              minRows={1}
              maxRows={6}
              required={question.required}
              inputRef={ref}
              value={value}
              onChange={onChange}
            />
            <div>
              {error &&
                <Alert severity="error" sx={{ marginTop: 1 }}>
                  {error?.type === "required" && "This field is required"}
                  {error?.type === "maxLength" && "This field should be less that 4000 characters"}
                </Alert>
              }
            </div>
          </Box>} 
        
        />
      ) : (
        <Controller
          control={control}
          name={`answers.${idx}.options`}
          rules={{
            required: question.required
          }}
          render={({field: {onChange, value}, fieldState: {error}}) => <Box sx={{width: '100%'}}>
            <CabAutocomplete<number, never>
              value={question.question_type === QuestionType.MULTI_SELECT 
                ? value ?? [] 
                : value && value.length ? value[0] : -1}
              multiple={question.question_type === QuestionType.MULTI_SELECT}
              placeholder={ !value || value.length === 0 ? "Select an answer" : ""}
              options={question.options ? question.options.map(option => ({
                value: option.id,
                label: option.name
              })) : []}
              // This is to assure we always return an array
              onChange={(v) => onChange(!v ? [] : v instanceof Array ? v : [v])}
            />
          </Box>} 
        
        />
      )}
    </FormControl>
  );
};