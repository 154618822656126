import { CabTextInput } from "@CabComponents/CabTextInput";
import { Box, FormLabel, Typography } from "@mui/material";
import { ReactElement, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { emailRegex } from "../../constants";
import { 
  ExternalMeetingInfo, 
  NormalizedExternalParticipant 
} from "../../store";


export const NoTimesWorkHeader = () => {
  return (
    <>
      Send a notice to the organizer
    </>
  );
};

export const NoTimesWorkSubHeader = () => {
  return (
    <>
      We'll let them know none of the available times work for you
    </>
  );
};

export interface NoTimesWorkProps {
  meeting: ExternalMeetingInfo | null;
  isBooking: boolean;
  isPreview?: boolean;
  prefillName?: string;
  prefillEmail?: string;
  onSubmit: (data: NormalizedExternalParticipant) => void;
  onFormStateChange?: (state: { isValid: boolean }) => void;
}

const NoTimesWork = ({
  meeting, isBooking, isPreview, prefillEmail, prefillName, onSubmit, onFormStateChange
}: NoTimesWorkProps): ReactElement => {

  const defaultParticipant: NormalizedExternalParticipant = {
    id: -1, name: prefillName || "", email: prefillEmail || "",
    first_response_date: null, meeting: meeting?.id || -1, email_hash: '', required: true,
    no_times_comment: null, calendar_access: null
  };

  const { control, formState, handleSubmit } = useForm<NormalizedExternalParticipant>({
    defaultValues: defaultParticipant
  });

  useEffect(() => {
    onFormStateChange?.(formState);
  }, [formState, onFormStateChange]);
  
  return (
    <Box display='flex' flexDirection='column' gap={4}>
      <Box display='flex' flexDirection='column' gap={2}>
        <Typography variant="h1" fontSize={26}>Your information and message</Typography>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box display='flex' flexDirection='column' gap={3}>
          <Box display='flex' flexDirection='column' gap={2}>
            <Controller 
              name={`name`}
              control={control}
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <Box display='flex' flexDirection='column'>
                  <Box display='flex' justifyContent='space-between'>
                    <FormLabel>Name*</FormLabel>
                  </Box>
                  <CabTextInput
                    {...field}
                    inputRef={ref}
                    sx={{width: '98%'}}
                    required
                  />
                </Box>
              )}/>
            <Controller 
              name={`email`}
              control={control}
              rules={{ required: true, pattern: emailRegex }}
              render={({ field: { ref, ...field } }) => (
                <Box display='flex' flexDirection='column'>
                  <FormLabel>Email*</FormLabel>
                  <CabTextInput
                    {...field}
                    type='email'
                    inputRef={ref}
                    sx={{width: '98%'}}
                    required
                  />
                </Box>
              )}/>
            <Controller 
              name={`no_times_comment`}
              control={control}
              rules={{ required: false }}
              render={({ field: { ref, ...field } }) => (
                <Box display='flex' flexDirection='column'>
                  <FormLabel>Comment</FormLabel>
                  <CabTextInput
                    {...field}
                    multiline
                    minRows={4}
                    type='email'
                    inputRef={ref}
                    sx={{width: '98%'}}
                  />
                </Box>
              )}/>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default NoTimesWork;
