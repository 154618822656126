import { Box, FormLabel, Grid, Skeleton, styled, Typography } from "@mui/material";
import { StaticDatePicker } from '@mui/x-date-pickers-pro';
import { DateTime } from "luxon";
import { ReactElement } from "react";
import colors from "../../../colors";
import TimeSlots from "./TimeSlots";
import { BookingSlot, BookingSlotsByDay } from "../../../store";
import { CabButton } from "@CabComponents/CabButton";
import { CabTimezoneInput } from "@CabComponents/CabTimezoneInput";
import CabSpinner from "@CabComponents/CabSpinner";
import { CabIcon } from "@CabComponents/CabIcon";
import { IoBan } from "react-icons/io5";
// import MobileDateTimeSelection from "./MobileDateTimeSelection";


// const dayFormat = 'cccc MMMM d, yyyy';
export const DateTimeSelectionHeader = ({ from }: { from: string }) => <><b>{from}</b> has invited you to a meeting</>;

export const DateTimeSelectionSubHeader = () => null;

export interface DateTimeSelectionProps {
  selectedDay: DateTime | null;
  orderedAvailableDates: DateTime[];
  slotsByDay: BookingSlotsByDay;
  currentTimezone: string;
  from: string;
  slotSelected: BookingSlot | null;
  isPreview?: boolean;
  hideTitle?: boolean;
  setCurrentTimeZone: (tz: string) => void;
  handleDaySelected: (daySelected: DateTime | null) => void;
  setSlotSelected: (slot: BookingSlot) => void;
  onConfirm: () => void;
  onNoTimesWork: () => void;
  isFramed?: boolean;
  onCalendarDateChange?: (date: DateTime) => void
  loading?: boolean;
  defaultYearMonth: [number, number] | null
  viewDate: DateTime;
  isEnterpriseBook?: boolean;
  showSkeletons?: boolean;
  isSmDown?: boolean;
  isSelectedDay?: boolean;
}

const DateTimeSelection = ({
  selectedDay, orderedAvailableDates, slotsByDay, currentTimezone, from, slotSelected, isPreview, hideTitle,
  setCurrentTimeZone, handleDaySelected, setSlotSelected, onConfirm, onNoTimesWork, isFramed, onCalendarDateChange,
  loading, defaultYearMonth, viewDate, isEnterpriseBook, showSkeletons, isSmDown, isSelectedDay
}: DateTimeSelectionProps) => {

  // if a conflicting day is selected with a link clear the selected day
  // useEffect(() => {
  //   if (!selectedDay || !slotsByDay[selectedDay.toMillis()]) {
  //     handleDaySelected(null);
  //   }
  // }, [handleDaySelected, selectedDay, slotsByDay, currentTimezone]);

  const selectedDayIsAvailable = selectedDay && orderedAvailableDates.some(d => d.hasSame(selectedDay, 'day'));

  const handleSetCurrentTimeZone = (value: string | null) => {
    if (value != null) {
      setCurrentTimeZone(value);
    }
  };
  
  /* if (isSmDown) {
    return (
      <MobileDateTimeSelection
        selectedDay={selectedDay}
        orderedAvailableDates={orderedAvailableDates}
        currentTimezone={currentTimezone}
        slotSelected={slotSelected}
        from={from}
        isPreview={isPreview}
        setCurrentTimeZone={setCurrentTimeZone}
        handleDaySelected={handleDaySelected}
        slotsByDay={slotsByDay}
        setSlotSelected={setSlotSelected}
        onConfirm={onConfirm}
        isSelectedDay={isSelectedDay}
        isFramed={isFramed}
        onCalendarDateChange={onCalendarDateChange}
        loading={!!loading}
        defaultYearMonth={defaultYearMonth}
        setNoTimesWork={setNoTimesWork}
        showSkeletons={showSkeletons}
      />
    );
  } */
 
  return (
    <Box display='flex' flexDirection='column' gap={2} width={{ md: 640 }}>
      <Box display='flex' flexDirection='column' gap={2}>
        <Grid container spacing={4} rowSpacing={3}>
          <Grid item sm={6.2}>
            <Box width='100%'>
              <FormLabel htmlFor="timezone-select">Timezone</FormLabel>
              <CabTimezoneInput
                id="timezone-select"
                label='Timezone'
                value={currentTimezone ? currentTimezone : ''}
                required={true}
                defaultValue={currentTimezone ? currentTimezone : ''}
                onChange={handleSetCurrentTimeZone}
                sx={{ width: '100%' }}
              />
            </Box>
          </Grid>
          <Grid item sm={5.8} />

          <Grid item sm={6.2}>
            <Box display='flex' flexDirection='column' gap={2} height='100%'>
              {showSkeletons ? (
                <Skeleton height='100%' width='100%' variant='rounded' />
              ) : (
                // TODO: we should really just use our CabDatePicker for this
                <StaticDayPicker
                  selectedDay={selectedDay}
                  orderedAvailableDates={orderedAvailableDates}
                  onDaySelected={handleDaySelected}
                  onCalendarDateChange={onCalendarDateChange}
                  defaultYearMonth={defaultYearMonth}
                  timezone={currentTimezone}
                  slotsLoading={!!loading}
                />
              )}
            </Box>
          </Grid>

          <Grid item sm={5.8}>
            <Box maxWidth={'300px'} display='flex' flexDirection='column' gap={2} maxHeight={338}>
              {/* {showSkeletons ? (
                <Skeleton variant='rounded' width='100%' height={26} />
              ) : (
                <Typography variant="h5">
                  {selectedDayIsAvailable ? selectedDay.toFormat(dayFormat) : 'Please Select a Day'}
                </Typography>
              )} */}
              {showSkeletons ? (
                <Skeleton height='100%' width='100%' variant='rounded' sx={{minHeight: '300px'}}/>
              ) : (
                <>
                  {!loading ? (
                    <>
                      <ScrollBox>
                        {selectedDayIsAvailable ? (
                          <TimeSlots
                            slots={selectedDay
                              ? slotsByDay[selectedDay.startOf('day').toMillis()] || []
                              : []}
                            currentTimeZone={currentTimezone}
                            slotSelected={slotSelected}
                            setSlotSelected={setSlotSelected}
                            onConfirm={onConfirm}
                            // isPreview={isPreview}
                          />
                        ) : (
                          orderedAvailableDates.length ? (
                            <Box display='flex' height='100%' width='100%' 
                              alignItems='center' justifyContent='center'>
                              No Day Selected
                            </Box>
                          ) : (
                            <Box display='flex' height='100%' width='100%' alignItems='center'
                              justifyContent='center' flexDirection='column' gap={1}>
                              <Typography fontSize={15}>
                                There are no available times in {viewDate.toLocaleString({ month: 'long' })}
                              </Typography>
                              {onCalendarDateChange && (
                                <CabButton
                                  onClick={() => onCalendarDateChange(viewDate.plus({ month: 1 }).set({ day: 1 }))}
                                  buttonType='tertiary'
                                >
                                  Check Next Month
                                </CabButton>
                              )}
                            </Box>
                          )
                        )}
                      </ScrollBox>
                    </>
                  ) : (
                    <Box
                      display="flex"
                      width="100%"
                      height="100%"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <CabSpinner scale={4} color='inherit' data-testid="calendar-spinner"/>
                    </Box>
                  )}
                </>
              )}
            </Box>
          </Grid>
          
          <Grid item sm={5.8} />
          <Grid item sm={6.2}>
            <Box bgcolor="white" paddingLeft={3} paddingRight={1.5}>
              <CabButton
                onClick={onNoTimesWork}
                buttonType="secondary"
                sx={{ width: '100%', borderStyle: 'dashed', height: 44, color: colors.black700 }}
                icon={<CabIcon Icon={IoBan} size={16} />}
              >
                None of these times work
              </CabButton>
            </Box>

          </Grid>
        </Grid>
      </Box>
      
    </Box>
  );
};

export default DateTimeSelection;

interface StaticDayPickerProps {
  selectedDay: DateTime | null;
  noDefault?: boolean;
  orderedAvailableDates: DateTime[];
  onDaySelected: (daySelected: DateTime | null) => void;
  onCalendarDateChange?: (date: DateTime) => void;
  defaultYearMonth: [number, number]|null;
  timezone?: string;
  slotsLoading: boolean;
}

const StaticDayPicker = ({
  selectedDay, onDaySelected, orderedAvailableDates, noDefault, defaultYearMonth, timezone,
  onCalendarDateChange = () => undefined, slotsLoading
}: StaticDayPickerProps): ReactElement => {
  const disableDate = (date: DateTime) => {
    return !orderedAvailableDates.find(d => d.hasSame(date, 'day'));
  };

  const value = noDefault || !selectedDay ? null : selectedDay.setZone(timezone);
  const selectedDateIsDisabled = value ? disableDate(value) : false;

  return <Box data-testid="static-date-picker" sx={{
    '& .MuiPickersArrowSwitcher-root .MuiButtonBase-root': {
      backgroundColor: colors.lavendar200,
      '&:hover': {
        backgroundColor: colors.lavendar500
      },
      '&.Mui-disabled': {
        backgroundColor: 'inherit'
      }
    }
  }}>
    {defaultYearMonth && (
      <StaticDatePicker
        sx={{ border: `1px solid ${colors.black200}`, borderRadius: 1 }}
        key={timezone}
        timezone={timezone}
        displayStaticWrapperAs='desktop'
        disablePast
        // minDate={orderedAvailableDates[0]}
        // maxDate={orderedAvailableDates[orderedAvailableDates.length - 1]}
        disableHighlightToday
        disabled={slotsLoading}
        openTo="day"
        onYearChange={(date: DateTime) => {
          // this is the only guaranteed way to make sure we get date with correct timezone
          const nextDate = DateTime.fromISO(date.toISODate() || "", { zone: timezone });
          onCalendarDateChange(nextDate);
          onDaySelected(null);
        }}
        onMonthChange={(date: DateTime) => {
          // this is the only guaranteed way to make sure we get date with correct timezone
          const nextDate = DateTime.fromISO(date.toISODate() || "", { zone: timezone });
          onCalendarDateChange(nextDate);
          onDaySelected(null);
        }}
        value={value}
        shouldDisableDate={(date) => date ? disableDate(date) : true}
        onChange={d => onDaySelected(d)}
        referenceDate={DateTime.fromObject({ year: defaultYearMonth[0], month: defaultYearMonth[1] })}
        slotProps={{
          previousIconButton: slotsLoading === true ? { disabled: true } : undefined,
          nextIconButton: { disabled: slotsLoading === true ? true : undefined },
          day: {
            sx: (theme) => ({
              backgroundColor: colors.lavendar200,
              '&:hover, &:focus': {
                backgroundColor: colors.lavendar500,
              },
              '&.MuiPickersDay-root.Mui-selected': {
                backgroundColor: colors.lavendar950,
                // this prevents the selected date becoming invisible when the date is disabled
                color: selectedDateIsDisabled ? colors.black700 : undefined,
                '&:hover': {
                  backgroundColor: colors.lavendar800,
                }
              },
              '&.MuiPickersDay-root.Mui-disabled': {
                backgroundColor: 'inherit',
              },
            }),
          },
        }}
      />
    )}
  </Box>;
};

const ScrollBox = styled(Box, { label: "ScrollBox" })({
  overflow: 'auto',
  // height: "fit-content",
  // marginBottom: 12,
  // maxHeight: 280,
  overflowX: 'hidden',
  overflowY: 'auto',
  paddingRight: 5,
  '::-webkit-scrollbar': {
    width: 8,
    height: 8,
    backgroundColor: colors.white900,
  },
  '::-webkit-scrollbar-thumb': {
    background: colors.black300,
    borderRadius: 4,
  }
});
