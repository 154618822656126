import { ReactNode } from "react";
import { SxProps, Typography } from "@mui/material";
import { Box } from "@mui/material";
import { SidePanel } from "./BookingSidePanel";
import { BookingSlot, ExternalMeetingInfo } from "../../../store/schedule/types";
import { TemplateVars } from "@CabComponents/CabTextTokenInput";
import { CabTextTokenDisplay } from "@CabComponents/CabTextTokenDisplay";
import { CabButton } from "@CabComponents/CabButton";
import { IoArrowBack } from "react-icons/io5";
import { CabIcon } from "@CabComponents/CabIcon";
import { CabTooltip } from "@CabComponents/CabTooltip";
import CabSpinner from "@CabComponents/CabSpinner";
import { MeetingBookingStep } from "../BookMeeting";
import colors from "../../../colors";

const BookMeetingWrapper = ({
  showSidePanel, loadingPage, meetingInfo, meetingTitle, meetingDescription, durationText, isAuthenticated,
  templateVars, sx, header, children, slotSelected, onBackButtonClick, step, isPreview, isBooking,
  formState, onSubmit, submitLabel, submitDisabled, submitLoading, showBackButton, showSubmitButton
}: {
  showSidePanel: boolean,
  loadingPage: boolean,
  meetingInfo: ExternalMeetingInfo,
  meetingTitle: string,
  meetingDescription: string,
  durationText: string,
  isAuthenticated: boolean,
  templateVars: TemplateVars,
  sx: SxProps,
  header: ReactNode,
  children: ReactNode,
  slotSelected?: BookingSlot,
  onBackButtonClick?: () => void,
  step: MeetingBookingStep,
  isPreview?: boolean,
  isBooking?: boolean,
  formState?: { isValid: boolean },
  onSubmit?: () => void,
  submitLabel?: string,
  submitDisabled?: boolean,
  submitLoading?: boolean,
  showBackButton?: boolean,
  showSubmitButton?: boolean
}) => {
  return (
    <Box sx={{ ...sx, display: 'flex', minWidth: 1050 }}>
      {showSidePanel && (
        <SidePanel
          logo={''}
          showSkeletons={loadingPage}
          name={meetingInfo ? (
            <Box>
              <CabTextTokenDisplay
                chipSx={{fontSize: '22px', padding: '12px'}}
                templateVars={templateVars}
              >
                {meetingTitle || meetingInfo.title_booked || meetingInfo.title}
              </CabTextTokenDisplay>
            </Box>
          ) : (
            <></>
          )}
          description={meetingInfo ? (
            <Box>
              <CabTextTokenDisplay
                chipSx={{fontSize: '14px', padding: '10px'}}
                templateVars={templateVars}
              >
                {meetingDescription}
              </CabTextTokenDisplay>
            </Box>
          ) : (
            <></>
          )}
          durationText={durationText}
          locations={[
            // { isConference: true, name: 'Zoom' },
            // { isConference: false, name: '1234 Really Long Street St, Anytown, USA' },
          ]}
          leaders={meetingInfo?.leaders || []}
          meetingId={ isAuthenticated ? meetingInfo?.id : undefined}
          slotSelected={slotSelected}
        />
      )}
      <Box sx={{ flexGrow: 1, width: '100%' }}>
        {!showSidePanel && (
          <Box display="flex" flexDirection="column" gap={2} padding={4} paddingBottom={0}>
            <Typography variant="h5" fontWeight={700}>
              <CabTextTokenDisplay
                chipSx={{fontSize: '22px', padding: '12px'}}
                templateVars={templateVars}
              >
                {meetingTitle || meetingInfo.title_booked || meetingInfo.title}
              </CabTextTokenDisplay>
            </Typography>
            <Typography
              variant="body1"
              sx={{
                overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box',
                WebkitLineClamp: 5, WebkitBoxOrient: 'vertical'
              }}
            >
              <CabTextTokenDisplay
                chipSx={{fontSize: '14px', padding: '10px'}}
                templateVars={templateVars}
              >
                {meetingDescription}
              </CabTextTokenDisplay>
            </Typography>
          </Box>
        )}
        <Box width="100%">{header}</Box>
        <Box flex={1} width="100%">{children}</Box>
        <Box width="100%">
          <Box display='flex' gap={1} padding={4} alignItems='center' justifyContent='flex-end'>
            {showBackButton && onBackButtonClick && (
              <CabButton
                buttonType="text"
                icon={<CabIcon Icon={IoArrowBack} color={colors.lavendar600} />}
                onClick={onBackButtonClick}
              >
                Back
              </CabButton>
            )}
            {showSubmitButton && onSubmit && (
              <CabTooltip
                title={isPreview ? 'This is just a preview. When your invited participant clicks this button, ' +
                'Cabinet will schedule the meeting on your behalf.' : ''}
                placement='top-start'
                wrapWithSpan
              >
                <Box>
                  <CabButton 
                    color='primary'
                    onClick={onSubmit}
                    disabled={submitDisabled || (formState && !formState.isValid)}
                  >
                    {submitLoading ? <CabSpinner scale={2} /> : submitLabel || 'Next'}
                  </CabButton>
                  {isPreview && (
                    <Typography color={colors.black700} marginTop={1}>
                      (This is a preview, the meeting cannot be booked from here)
                    </Typography>
                  )}
                </Box>
              </CabTooltip>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BookMeetingWrapper;
