import { Alert, Box, Link, Skeleton, Typography } from "@mui/material";
import { ReactElement, ReactNode, useEffect, useMemo, useRef, useState } from "react";
import CabinetPage from "../../components/Common/CabinetPage";
import { 
  BookingSlot, BookingSlotsByDay, ExternalMeetingInfo, FetchReturn,
  MeetingQuestionAnswerSubmission, NormalizedExternalParticipant
} from "../../store";
import {
  getAnswerString, getTemplateVars, humanReadableDuration, replaceTemplateVariables
} from "../../utils/scheduleUtils";
import colors from "../../colors";
import DateTimeSelection, { DateTimeSelectionHeader, DateTimeSelectionSubHeader } from "./DateTimeSelection";
import AttendeeInfo, { AttendeeInfoHeader, AttendeeInfoSubHeader } from "./AttendeeInfo";
import Confirmation, { ConfirmationHeader, ConfirmationSubHeader } from "./Confirmation";
// import PollResponseCTA from "../../components/Meeting/PollResponseCTA/PollResponseCTA";
import { /*EVENT_TYPE,*/ PAGE_URL } from "../../constants";
// import MeetingCancelation from "../../components/Meeting/MeetingCancelation/MeetingCancelation";
import { useElementBreakpoint } from "../../utils/hooks";
import { DateTime } from "luxon";
import NoTimesWork, { NoTimesWorkHeader, NoTimesWorkSubHeader } from "./NoTimesWork";
import { NAVY_LOGO_SRC } from "../../resourceUrls";
import CabSpinner from "@CabComponents/CabSpinner";
import { Link as RouterLink } from 'react-router-dom';
import { PublicOrganization } from "../../store/cabinetApi/generated/core";
import BookMeetingWrapper from "./BookMeetingWrapper";


export enum MeetingBookingStep {
  DATE_SELECTION = 0,
  MEETING_INFO_STEP = 1,
  MEETING_CONFIRMATION = 2,
  NO_TIMES_WORK = 3
}

export type Props = {
  step: MeetingBookingStep;
  onBackButtonClick?: () => void;
  meetingInfo: ExternalMeetingInfo;
  isAuthenticated: boolean;
  isBooking: boolean;
  isPreview?: boolean;
  isReschedulingURL?: boolean;
  allowAddParticipants: boolean;
  confirmed: boolean;
  isBadResponse: boolean;
  isSelectedDay?: boolean;
  selectedDay: DateTime | null;
  orderedAvailableDates: DateTime[];
  currentTimezone: string;
  slotSelected: BookingSlot | null;
  setCurrentTimeZone: (tz: string) => void;
  handleDaySelected: (daySelected: DateTime | null) => void;
  slotsByDay: BookingSlotsByDay;
  setSlotSelected: (slot: BookingSlot) => void;
  setStep: (step: MeetingBookingStep) => void
  onConfirm: () => void;
  onNoTimesWork: () => void;
  handleScheduleMeeting: (externalParticipants: NormalizedExternalParticipant[], 
    meetingQuestionAnswers?: MeetingQuestionAnswerSubmission[]) => void;
  prefillName?: string;
  prefillEmail?: string;
  isFramed?: boolean;
  onCalendarDateChange?: (date: DateTime) => void;
  loading: boolean;
  defaultYearMonth: [number, number]|null;
  rebookParticipants: NormalizedExternalParticipant[];
  rebookAnswers: MeetingQuestionAnswerSubmission[];
  viewDate: DateTime;
  organization?: PublicOrganization;
  isEnterpriseBook?: boolean;
  submitNoTimesWorkForm: (data: NormalizedExternalParticipant) => void;
  noTimesWorkSent: boolean;
  loadingPage: boolean;
  loadingAnswers: boolean;
  questionAnswers: MeetingQuestionAnswerSubmission[];
};

export const BookMeeting = ({
  meetingInfo, selectedDay, orderedAvailableDates, slotsByDay, currentTimezone, step, slotSelected,
  isPreview, isReschedulingURL, isBooking, setCurrentTimeZone, handleDaySelected, setSlotSelected, onConfirm,
  onNoTimesWork, setStep, handleScheduleMeeting, isAuthenticated, allowAddParticipants, confirmed,
  isBadResponse, isSelectedDay, prefillEmail, prefillName, onCalendarDateChange, loading, defaultYearMonth,
  rebookParticipants, rebookAnswers, viewDate, organization, isEnterpriseBook, submitNoTimesWorkForm,
  noTimesWorkSent, loadingPage, onBackButtonClick, loadingAnswers, questionAnswers, isFramed
}: Props): ReactElement => {
  const [/*drawerWidth*/, setDrawerWidth] = useState(Math.max(300, (window.innerWidth * 0.2)));
  const [name, setName] = useState('');
  const [logoLoaded, setLogoLoaded] = useState(false);
  const [formState, setFormState] = useState<{ isValid: boolean }>({ isValid: true });
  const [submitLoading, setSubmitLoading] = useState(false);
  
  const containerRef = useRef<HTMLDivElement>(null);
  const stepBreakpoint = useElementBreakpoint(containerRef, {790: "xs", 791: "sm", 1100: "md", 1101: "lg"}, 'lg');

  const isLgDown = ["xs", "sm", "md"].some(bp => bp === stepBreakpoint);
  const isSmDown = ["xs"].some(bp => bp === stepBreakpoint);
  const durationText = humanReadableDuration(meetingInfo.duration_minutes || 0);
  
  const answers = useMemo(() => meetingInfo.questions?.map((question) => (
    {
      questionId: question.id,
      question: question.title, 
      answer: getAnswerString(questionAnswers.find(a => a.question === question.id), question)
    }
  )) || [], [meetingInfo.questions, questionAnswers]);

  const meetingTitle = replaceTemplateVariables(
    meetingInfo.title_booked || meetingInfo.title || '', name, answers);
  const templateVars = getTemplateVars(meetingInfo.questions?.map((q) => ({ [q.id]: q }))
    .reduce((a, b) => ({ ...a, ...b }), {}));

  const meetingDescription = replaceTemplateVariables(
    meetingInfo.description_booked || meetingInfo.description || '', name, answers);

  const from = meetingInfo.meeting_organizer_override
    || `${meetingInfo.create_user.first_name} ${meetingInfo.create_user.last_name}`; 

  useEffect(() => {
    function handleResize() {
      setDrawerWidth(Math.min(Math.max(300, (window.innerWidth * 0.2)), 450));
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSubmit = async () => {
    setSubmitLoading(true);
    try {
      let form;
      switch (step) {
        case MeetingBookingStep.DATE_SELECTION:
          onConfirm();
          break;
        case MeetingBookingStep.MEETING_INFO_STEP:
          // The form submission is handled by the AttendeeInfo component
          // We just need to trigger the form submit event
          form = document.querySelector('form');
          if (form) {
            form.requestSubmit();
          }
          break;
        case MeetingBookingStep.NO_TIMES_WORK:
          // The form submission is handled by the NoTimesWork component
          // We just need to trigger the form submit event
          form = document.querySelector('form');
          if (form) {
            form.requestSubmit();
          }
          break;
      }
    } finally {
      setSubmitLoading(false);
    }
  };

  const getSubmitLabel = () => {
    switch (step) {
      case MeetingBookingStep.DATE_SELECTION:
        return 'Next';
      case MeetingBookingStep.MEETING_INFO_STEP:
        return 'Book Meeting';
      case MeetingBookingStep.NO_TIMES_WORK:
        return 'Send Notification';
      case MeetingBookingStep.MEETING_CONFIRMATION:
        return '';
      default:
        return 'Next';
    }
  };

  const steps = useMemo(() => {
    const allSteps: Record<MeetingBookingStep, [ReactNode, ReactNode, ReactNode]> = {
      [MeetingBookingStep.DATE_SELECTION]: [
        <DateTimeSelectionHeader from={from} key="dateTimeSelectionHeader" />,
        <DateTimeSelectionSubHeader key="dateTimeSelectionSubHeader" />,
        <DateTimeSelection
          key="dateTimeSelection"
          isSmDown={isSmDown}
          selectedDay={selectedDay}
          isSelectedDay={isSelectedDay}
          orderedAvailableDates={orderedAvailableDates}
          currentTimezone={currentTimezone}
          from={from}
          slotSelected={slotSelected}
          isPreview={isPreview}
          setCurrentTimeZone={setCurrentTimeZone}
          handleDaySelected={handleDaySelected}
          slotsByDay={slotsByDay}
          setSlotSelected={setSlotSelected}
          onConfirm={onConfirm}
          onNoTimesWork={onNoTimesWork}
          hideTitle={isLgDown}
          isFramed={isFramed}
          onCalendarDateChange={onCalendarDateChange}
          loading={loading}
          defaultYearMonth={defaultYearMonth}
          viewDate={viewDate}
          isEnterpriseBook={isEnterpriseBook}
          showSkeletons={loadingPage}
        />,
      ],
      [MeetingBookingStep.MEETING_INFO_STEP]: [
        <AttendeeInfoHeader key="attendeeInfoHeader" />,
        <AttendeeInfoSubHeader key="attendeeInfoSubHeader" />,
        <AttendeeInfo
          key="attendeeInfo"
          selectedDay={selectedDay}
          currentTimezone={currentTimezone}
          slotSelected={slotSelected}
          meeting={meetingInfo}
          isBooking={isBooking}
          setName={setName}
          handleScheduleMeeting={handleScheduleMeeting}
          isReschedulingURL={isReschedulingURL}
          allowAddParticipants={allowAddParticipants}
          isPreview={isPreview}
          prefillEmail={prefillEmail}
          prefillName={prefillName}
          rebookParticipants={rebookParticipants}
          rebookAnswers={rebookAnswers}
          onFormStateChange={setFormState}
        />
      ],
      [MeetingBookingStep.MEETING_CONFIRMATION]: [
        <ConfirmationHeader key="confirmationHeader"
          confirmed={confirmed}
          noTimesWork={Object.values(meetingInfo.participants).every(p => p.no_times_comment)}
          isErrorNotice={isBadResponse}
        />,
        <ConfirmationSubHeader key="confirmationSubHeader"
          confirmed={confirmed}
          noTimesWork={Object.values(meetingInfo.participants).every(p => p.no_times_comment)}
          isErrorNotice={isBadResponse}
        />,
        <Confirmation
          key="confirmation"
          selectedDay={selectedDay}
          slotSelected={slotSelected}
          meeting={meetingInfo}
          currentTimezone={currentTimezone}
          confirmed={confirmed}
          noTimesWork={Object.values(meetingInfo.participants).every(p => p.no_times_comment)}
          isErrorNotice={isBadResponse}
          noTimesWorkSent={noTimesWorkSent}
          questionAnswers={questionAnswers}
          loadingAnswers={loadingAnswers}
        />,
      ],
      [MeetingBookingStep.NO_TIMES_WORK]: [
        <NoTimesWorkHeader key="noTimesWorkHeader" />,
        <NoTimesWorkSubHeader key="noTimesWorkSubHeader" />,
        <NoTimesWork
          key="no_times_work"
          meeting={meetingInfo}
          isBooking={isBooking}
          isPreview={isPreview}
          prefillEmail={prefillEmail}
          prefillName={prefillName}
          onSubmit={submitNoTimesWorkForm}
          onFormStateChange={setFormState}
        />,
      ],
    };

    return allSteps;
  }, [
    isSmDown, selectedDay, orderedAvailableDates, currentTimezone, slotSelected, meetingInfo, isPreview,
    setCurrentTimeZone, handleDaySelected, slotsByDay, setSlotSelected, onConfirm, isSelectedDay, isFramed,
    onCalendarDateChange, loading, defaultYearMonth, isLgDown, viewDate, isEnterpriseBook,
    isBooking, handleScheduleMeeting, isReschedulingURL, allowAddParticipants, onNoTimesWork,
    prefillEmail, prefillName, rebookParticipants, rebookAnswers, confirmed, isBadResponse,
    submitNoTimesWorkForm, noTimesWorkSent, from, loadingPage, loadingAnswers, questionAnswers,
    setFormState
  ]);
  
  const [Header, SubHeader, Content] = steps[step];

  const logo = meetingInfo.create_user.active_license?.organization.logo || null;
  
  const showBackButton = step === MeetingBookingStep.MEETING_INFO_STEP || step === MeetingBookingStep.NO_TIMES_WORK;
  const showSubmitButton = step == MeetingBookingStep.MEETING_INFO_STEP || step == MeetingBookingStep.NO_TIMES_WORK;

  return (
    <Box display='flex' flexDirection='column' justifyContent='space-between' height='100%'>
      <Box ref={containerRef} display={isEnterpriseBook ? 'flex' : undefined} 
        justifyContent={isEnterpriseBook ? 'center' : 'inherit'}
        sx={{ flex: 1, width: '100%', backgroundColor: isEnterpriseBook ? 'unset' : colors.greyBackdrop }} 
        data-testid="book-meeting-container" paddingTop={isFramed ? 4 : 0}
      >
        <Box display='flex' flexDirection='column' gap={6} alignItems='center' marginTop={6}>
          <Box display='flex' flexDirection='column' alignItems='center' gap={4}>
            {!(isFramed || isEnterpriseBook) && (
              loadingPage ? (
                <>
                  <Skeleton height={74} width={200} variant='rounded' />
                  <Skeleton variant='rounded' height={16} width={500} sx={{maxWidth: '600px' }}/>
                </>
              ) : (
                <>
                  {logo && (
                    <Box component='img' src={logo} alt=''
                      {...(logoLoaded ? { maxHeight: 124, objectFit: 'contain' } : { width: "100%", height: 74 })}
                      onLoad={() => setLogoLoaded(true)}
                      sx={{ maxWidth: 250 }}
                    />
                  )}

                  <Typography variant="h5" fontFamily="sentient" fontSize={30} fontWeight={300}>
                    {Header}
                  </Typography>

                  <Typography variant="body2" color={colors.calmGrey600} fontSize={20}>
                    {SubHeader}
                  </Typography>
                </>
              )
            )}
          </Box>

          <BookMeetingWrapper
            loadingPage={loadingPage}
            meetingInfo={meetingInfo}
            meetingTitle={meetingTitle}
            meetingDescription={meetingDescription}
            durationText={durationText}
            slotSelected={slotSelected || undefined}
            isAuthenticated={isAuthenticated}
            templateVars={templateVars}
            header={<></>}
            showSidePanel={!isLgDown && !isEnterpriseBook}
            onBackButtonClick={onBackButtonClick}
            showBackButton={showBackButton}
            showSubmitButton={showSubmitButton}
            step={step}
            isPreview={isPreview}
            isBooking={isBooking}
            formState={formState}
            onSubmit={handleSubmit}
            submitLabel={getSubmitLabel()}
            submitDisabled={isBooking}
            submitLoading={submitLoading}
            sx={{
              borderRadius: 5, 
              border: `1px solid ${colors.calmGrey200}`,
              boxShadow: `0px 4px 12px ${colors.calmGrey200}`,
              backgroundColor: colors.white900,
              overflow: 'hidden',
              margin: { xs: 4, lg: 1 },
            }}
          >
            <Box width="100%" padding={4}>
              {Content}
            </Box>
          </BookMeetingWrapper>

          <Box display='flex' flexDirection='column' alignItems='center' gap={1.5}>
            <Typography variant="caption" fontSize={12}>Powered by</Typography>
            <Box component='img' src={NAVY_LOGO_SRC} alt='Cabinet' width={110} />
          </Box>
        </Box>
      </Box>
      
      {meetingInfo.id && (
        <Box
          display='flex'
          position='fixed'
          bottom={0}
          left={0}
          padding={2}
          flexDirection='column'
          width="300px"
        >
          <Typography variant="body2">Are you the meeting owner?</Typography>
          <Box display='flex'>
            <Link component={RouterLink} to={`${PAGE_URL.SCHEDULE}/${meetingInfo.id}`} variant="body2" 
              color={colors.black900} fontWeight={500}
            >
              Click here
            </Link>
            <Typography variant="body2">&nbsp;to make changes.</Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export type PageProps = {
  isAuthenticated: boolean;
  step: MeetingBookingStep;
  onBackButtonClick?: () => void;
  meetingInfo: ExternalMeetingInfo | null;
  selectedDay: DateTime | null;
  orderedAvailableDates: DateTime[];
  slotsByDay: BookingSlotsByDay;
  currentTimezone: string;
  slotSelected: BookingSlot | null;
  isPreview?: boolean;
  isReschedulingURL?: boolean;
  isCancelingURL?: boolean;
  isBooking: boolean;
  isBadResponse: boolean;
  confirmed: boolean;
  allowAddParticipants: boolean;
  organization?: PublicOrganization;
  isSelectedDay?: boolean;
  setCurrentTimeZone: (tz: string) => void;
  handleDaySelected: (daySelected: DateTime | null) => void;
  setSlotSelected: (slot: BookingSlot) => void;
  onConfirm: () => void;
  onNoTimesWork: () => void;
  setStep: (step: MeetingBookingStep) => void
  handleScheduleMeeting: (externalParticipants: NormalizedExternalParticipant[], 
    meetingQuestionAnswers?: MeetingQuestionAnswerSubmission[]) => void;
  handleCancelMeeting: (message: string) => Promise<FetchReturn | undefined>;
  prefillName?: string;
  prefillEmail?: string;
  isFramed?: boolean;
  onCalendarDateChange?: (date: DateTime) => void
  loading: boolean;
  defaultYearMonth: [number, number]|null;
  rebookParticipants: NormalizedExternalParticipant[];
  rebookAnswers: MeetingQuestionAnswerSubmission[];
  viewDate: DateTime;
  noCabPage?: boolean;
  isEnterpriseBook?: boolean;
  submitNoTimesWorkForm: (data: NormalizedExternalParticipant) => void
  noTimesWorkSent: boolean
  loadingCalendar: boolean;
  loadingAnswers: boolean;
  questionAnswers: MeetingQuestionAnswerSubmission[];
};

const BoxWrapper = ({children}: {children: ReactElement[]}) => (
  <Box width='100%'>{children}</Box>
);

const CabinetPageWrapper = ({children}: {children: ReactElement[]}) => (
  <CabinetPage
    pageName={'Book Meeting'}
    noHeader
  >
    {children}
  </CabinetPage>
);

const BookMeetingPage = ({
  isAuthenticated, step, meetingInfo, selectedDay, orderedAvailableDates, slotsByDay, currentTimezone, 
  isPreview, slotSelected, isBooking, isReschedulingURL, setCurrentTimeZone, handleDaySelected, setSlotSelected, 
  onConfirm, onNoTimesWork, setStep, handleScheduleMeeting, isCancelingURL, organization, loadingAnswers,
  questionAnswers, handleCancelMeeting, allowAddParticipants, confirmed, isBadResponse, isSelectedDay, prefillEmail,
  prefillName, isFramed, onCalendarDateChange, loading, defaultYearMonth, rebookParticipants, rebookAnswers, viewDate,
  noCabPage, isEnterpriseBook, submitNoTimesWorkForm, noTimesWorkSent, loadingCalendar, onBackButtonClick
}: PageProps): ReactElement => {
  
  const Wrapper = noCabPage || isPreview ? BoxWrapper : CabinetPageWrapper;

  return (
    <Wrapper>
      {/* isCancelingURL ? (
        meetingInfo && organization ? (
          <MeetingCancelation
            meeting={meetingInfo}
            dateSet={slotSelected}
            currentTimeZone={currentTimezone}
            leaders={meetingInfo.leaders}
            organization={organization}
            name={''}
            confirmed={true}
            handleCancelMeeting={handleCancelMeeting}
          />
        ) : (
          <Box hidden={!loading} height='100%' display='flex' alignItems='center' data-testid="book-meeting-spinner">
            <CabSpinner scale={4} color='inherit' />
          </Box>
        )
      ) : } */}
      {meetingInfo
        ? <BookMeeting
          meetingInfo={meetingInfo}
          isAuthenticated={isAuthenticated}
          selectedDay={selectedDay}
          orderedAvailableDates={orderedAvailableDates}
          currentTimezone={currentTimezone}
          step={step}
          onBackButtonClick={onBackButtonClick}
          slotSelected={slotSelected}
          isPreview={isPreview}
          isReschedulingURL={isReschedulingURL}
          isBooking={isBooking}
          setCurrentTimeZone={setCurrentTimeZone}
          handleDaySelected={handleDaySelected}
          slotsByDay={slotsByDay}
          setSlotSelected={setSlotSelected}
          onConfirm={onConfirm}
          onNoTimesWork={onNoTimesWork}
          setStep={setStep}
          handleScheduleMeeting={handleScheduleMeeting}
          allowAddParticipants={allowAddParticipants}
          confirmed={confirmed}
          isBadResponse={isBadResponse}
          isSelectedDay={isSelectedDay}
          prefillEmail={prefillEmail}
          prefillName={prefillName}
          isFramed={isFramed}
          onCalendarDateChange={onCalendarDateChange}
          loading={loadingCalendar}
          defaultYearMonth={defaultYearMonth}
          rebookParticipants={rebookParticipants}
          rebookAnswers={rebookAnswers}
          viewDate={viewDate}
          organization={organization}
          isEnterpriseBook={isEnterpriseBook}
          submitNoTimesWorkForm={submitNoTimesWorkForm}
          noTimesWorkSent={noTimesWorkSent}
          loadingPage={loading}
          loadingAnswers={loadingAnswers}
          questionAnswers={questionAnswers}
        />
        : 
        <Box hidden={loading} display='flex' justifyContent='center' marginTop={5}>
          <Alert severity="warning">
            No meeting was found at this link
          </Alert>
        </Box>
      }
      <Box hidden={!loading} height='100%' display='flex' alignItems='center' data-testid="book-meeting-spinner">
        <CabSpinner scale={4} color='inherit' />
      </Box>
    </Wrapper>
  );
};

export default BookMeetingPage;
