import { ReactElement, ReactNode, useState } from 'react';
import { Box, styled, Drawer, Typography, Skeleton, Divider } from '@mui/material';
import { CabAvatar, CabIcon, CabTooltip } from '@CabComponents';
import colors from '../../../colors';
import { BookingSlot, PublicLeader } from '../../../store';
import { DateTime } from 'luxon';
import { IoLocation, IoVideocam } from 'react-icons/io5';


const SIDE_PANEL_WIDTH = '20vw';

const DEFAULT_LEADERS_TO_SHOW = 3;

const DrawerContent = styled(Box, { label: "DrawerContent" })(
  {
    padding: '32px 24px 24px 24px',
    width: '100%',
    flex: 1,
  }
);

interface SidePanelProps {
  logo: string;
  name: string | ReactNode;
  description: string | ReactNode;
  durationText: string;
  participantLength?: number;
  leaders?: PublicLeader[];
  showSkeletons?: boolean;
  meetingId?: number;
  slotSelected?: BookingSlot;
  locations?: {
    isConference: boolean;
    name: string;
  }[];
}

export const SidePanel = ({
  logo, name, description, durationText, participantLength, leaders, showSkeletons, meetingId,
  slotSelected, locations
}: SidePanelProps): ReactElement => {
  const [open, setOpen] = useState(true);
  const [showAllLeaders, setShowAllLeaders] = useState(false);

  const date = slotSelected ? 
    DateTime.fromISO(slotSelected.start).toFormat('MMM d, yyyy') : '';
  const startTime = slotSelected ? 
    DateTime.fromISO(slotSelected.start).toFormat('h:mm a') : '';
  const endTime = slotSelected ?
    DateTime.fromISO(slotSelected.end).toFormat('h:mm a') : '';
  
  return (
    <Drawer
      variant="permanent"
      anchor="left"
      open={open}
      onClose={() => setOpen(false)}
      sx={{ width: SIDE_PANEL_WIDTH, maxWidth: 450, flexShrink: 0, '& .MuiDrawer-paper': { position: 'relative' } }}
    >
      <DrawerContent>
        <Box marginTop={1} display="flex" gap={2.5} flexDirection="column">
          {showSkeletons ? (
            <>
              <Skeleton variant='rounded' width={120} height={30} />
              <Skeleton width='100%' height={30} variant='rounded' />
              <Box gap={.5} display="flex" flexWrap="wrap">
                <Skeleton variant='rounded' width={120} height={30} />
                <Skeleton variant='rounded' width={120} height={30} />
              </Box>
            </>
          ) : (
            <>
              <Typography variant="h1">{name}</Typography>

              <Box display="flex" gap={1} alignItems="center">
                <Typography variant="body1" color={colors.calmGrey600} fontWeight={700}>Duration:</Typography>
                <Typography variant="body1" color={colors.calmGrey500}>{durationText}</Typography>
              </Box>
              
              {!!locations?.length && (
                <Box display="flex" gap={1} flexDirection="column">
                  {locations.map(location => (
                    <CabTooltip title={location.name} key={location.name} wrapWithSpan>
                      <Box display="flex" gap={1} alignItems="center">
                        <CabIcon
                          Icon={location.isConference ? IoVideocam : IoLocation}
                          color={colors.calmGrey500}
                          sx={{ fontSize: 20 }}
                        />
                        <Typography variant="body1" color={colors.calmGrey500}
                          noWrap sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                        >
                          {location.name}
                        </Typography>
                      </Box>
                    </CabTooltip>
                  ))}
                </Box>
              )}

              {participantLength !== undefined && (
                <Box display="flex" gap={1} alignItems="center">
                  <Typography variant="body1" color={colors.calmGrey600} fontWeight={700}>Participants:</Typography>
                  <Typography variant="body1" color={colors.calmGrey500}>{participantLength}</Typography>
                </Box>
              )}
              
              {leaders && (
                <Box>
                  <Divider />
                  <Box display="flex" flexDirection="column" gap={1} marginTop={2} maxHeight={170} overflow="auto"
                    sx={{
                      '::-webkit-scrollbar': {
                        width: 8, height: 8, backgroundColor: colors.white900,
                      },
                      '::-webkit-scrollbar-thumb': {
                        background: colors.black300, borderRadius: 4,
                      }
                    }}
                  >
                    {leaders.slice(
                      0,
                      !showAllLeaders && leaders.length > DEFAULT_LEADERS_TO_SHOW ? DEFAULT_LEADERS_TO_SHOW
                        : leaders.length
                    ).map(leader => (
                      <Box key={leader.id} display="flex" alignItems="center" gap={2}>
                        <CabAvatar
                          name={`${leader.first_name} ${leader.last_name}`}
                          src={leader.pic_url}
                          color={leader.color}
                          sx={{ width: 36, height: 36, fontSize: 16 }}
                        />
                        <CabTooltip title={`${leader.first_name} ${leader.last_name}`} key={leader.id} wrapWithSpan>
                          <Typography variant="body2">
                            {`${leader.first_name} ${leader.last_name}`}
                          </Typography>
                          <Typography variant="body2" color={colors.calmGrey600}>
                            {""}
                          </Typography>
                        </CabTooltip>
                      </Box>
                    ))}
                  </Box>
                  {leaders.length > DEFAULT_LEADERS_TO_SHOW && (
                    <Typography
                      variant="body2"
                      sx={{
                        color: colors.black700,
                        cursor: 'pointer',
                        mt: 1
                      }}
                      onClick={() => setShowAllLeaders(!showAllLeaders)}
                    >
                      {showAllLeaders ? 'Show less' : 'View all'}
                    </Typography>
                  )}
                </Box>
              )}

              {startTime && date && (
                <Box>
                  <Typography variant="body2" fontWeight={600} color={colors.calmGrey900}>
                    {date}
                  </Typography>
                  <Typography variant="body2" color={colors.calmGrey600}>
                    {`${startTime} - ${endTime}`}
                  </Typography>
                </Box>
              )}
              
              <Divider />

              <Typography
                component="div"
                variant="body1" 
                whiteSpace='pre-wrap' 
                color={colors.black700}
                sx={{
                  maxHeight: 400,
                  overflow: 'auto',
                  overflowX: 'hidden',
                  overflowY: 'auto',
                  paddingRight: 1,
                  '::-webkit-scrollbar': {
                    width: 8, height: 8, backgroundColor: colors.white900,
                  },
                  '::-webkit-scrollbar-thumb': {
                    background: colors.black300, borderRadius: 4,
                  }
                }}>
                {description}
              </Typography>
            </>
          )}
        </Box>
      </DrawerContent>
    </Drawer>
  );
};
