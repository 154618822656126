import { DateTime } from 'luxon';
import { ReactElement } from 'react';
import { CabButton, CabTooltip } from '@CabComponents';
import colors from '../../../colors';
import { Box } from '@mui/material';
import { BookingSlot } from '../../../store';

export interface TimeSlotsProps {
  slots: BookingSlot[];
  currentTimeZone: string;
  isPreview?: boolean;
  slotSelected: BookingSlot | null;
  setSlotSelected: (slot: BookingSlot) => void;
  onConfirm: () => void;
}

const TimeSlots = ({
  slots, currentTimeZone, slotSelected, isPreview, setSlotSelected, onConfirm
}: TimeSlotsProps): ReactElement => {
  return <Box display='flex' flexDirection='column' gap={1}>
    {slots.map(slot => {
      const timeText = DateTime.fromISO(slot.start, { zone: currentTimeZone }).toFormat('h:mm a') + ' - ' 
        + DateTime.fromISO(slot.end, {zone: currentTimeZone}).toFormat('h:mm a');
      return (
        <Box key={slot.start} display='flex' flexDirection='row' gap={1} height={46}>
          {slotSelected?.start === slot.start ? (
            <>
              <CabButton color="accent" sx={{ backgroundColor: colors.navyDark, flex: 6 }}>
                {timeText}
              </CabButton>
              <CabButton onClick={onConfirm} sx={{ flex: 2 }}>
                Confirm
              </CabButton>
            </>
          ) : (
            <CabTooltip
              title={isPreview
                ? 'This is just a preview. When your invited participant clicks this button, Cabinet will' +
                  ' schedule the meeting on your behalf'
                : ''}
              placement='bottom'
              wrapWithSpan
            >
              <CabButton
                onClick={() => setSlotSelected(slot)}
                buttonType="secondary"
                sx={{ width: '100%' }}
              >
                {timeText}
              </CabButton>
            </CabTooltip>
          )}
        </Box>
      );
    })}
  </Box>;
};

export default TimeSlots;
